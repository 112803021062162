import React from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import { Bars } from 'react-loading-icons';

import { redLightBG } from '../../config/colors';
import { usePlayer } from '../../store';
import { Track } from '../../services/SoundSuitServiceTypes';
// import components
import TagTrack from './TagTrack';

interface Props {
  track: Track;
  isLargeScreen?: boolean;
  position?: 'queue' | 'recent';
};

const TrackCover: React.FC<Props> = props => {

  function handleClickOnTrack() {
    const index = playlist.findIndex(t => t._id === track._id);
    if(index > -1) {
      setPositionTrack(index);
    }
  }

  const {
    track,
    isLargeScreen = true,
    position
  } = props;

  const playlist = usePlayer(statePlayer => statePlayer.state.playlist);
  const setPositionTrack = usePlayer.getState().reducers.setPositionTrack;

  const artwork_url = decodeURIComponent(decodeURIComponent(track.artwork as string));

  return (
    <Container isLarge={isLargeScreen} onClick={handleClickOnTrack}>
      <WrapperImage>
        <Image
          width={isLargeScreen ? "100%" : 70}
          height={isLargeScreen ? "100%" : 70}
          src={artwork_url}
          placeholder={<Bars speed={2} fill={redLightBG} stroke={redLightBG} height="1em" />}
          preview={false}
        />
      </WrapperImage>
      <TagTrack
        position={position}
      />
    </Container>
  );
}

const Container = styled.div<{isLarge: boolean}>`
  position: relative;
  width: ${({isLarge}) => isLarge ? '120px':'70px'};
  height: ${({isLarge}) => isLarge ? '120px':'70px'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
`;
const WrapperImage = styled.div<{isLarge: boolean}>`
  overflow: hidden;
`;

export default TrackCover;