import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import {useQueries} from 'react-query';
import {useTranslation} from 'react-i18next';
import {UnorderedListOutlined} from '@ant-design/icons';
import { Modal } from 'antd';

import scheduleIcon from '../../assets/icons/scheduleIcon.png';
import stationIcon from '../../assets/icons/stationsIcon.png';
import playlistIcon from '../../assets/icons/playlistsIcon.png';
import {
  redBG,
  grey,
  lightgray,
  white,
  veryVeryLightGrey,
  black
} from '../../config/colors';
import {
  getZoneById
} from '../../services/SoundSuitService';
import {
  TypeMedia,
  Zone,
  MediaToPlay
} from '../../services/SoundSuitServiceTypes';
import { capitalizeFirstLetter} from '../../utils/functions';
import {usePlayer} from '../../store';
// import components
import Text from '../atoms/Text';
import SelectedMediaLabel from './SelectedMediaLabel';
import ModalMedias from './ModalMedias';
import ModalMediasButton from './ModalMediasButton';

interface Props {
}

const BarOptionsPlayback: React.FC<Props> = props => {

  function modalCancel() {
    setOpenModalMedia(false);
  }

  async function handleModeChange(type: TypeMedia) {
    if (type === mediaToPlay?.type) {
      return;
    }

    try {
      if (currentChoiceMedia && typeof currentChoiceMedia[type] !== 'undefined') {
        await setMediaToPlay(currentChoiceMedia[type]);
      } else {
        if (dataZone) {
          const keyMedia = `authorized${capitalizeFirstLetter(type)}s`;
          // @ts-ignore
          const defaultId: string | undefined = dataZone?.[keyMedia]?.[0] || undefined;
          if (defaultId) {
            const mediaToSend: MediaToPlay = { id: defaultId, type };
            await setMediaToPlay(mediaToSend);
          }
        }
      }
      toggleRefreshTracks();

    } catch (error) {
      console.log('ERROR changing media: ', error)
    }
  }

  const {t} = useTranslation();

  const mediaToPlay = usePlayer(statePlayer => statePlayer.state.mediaToPlay);
  const currentChoiceMedia = usePlayer(statePlayer => statePlayer.state.currentChoiceMedia);
  const zoneSelected = usePlayer(statePlayer => statePlayer.state.zoneSelected);
  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;

  const [openModalMedia, setOpenModalMedia] = useState(false);

  const [openModalMediaSchedule, setOpenModalMediaSchedule] = useState(false);
  const [openModalMediaStation, setOpenModalMediaStation] = useState(false);
  const [openModalMediaPlaylist, setOpenModalMediaPlaylist] = useState(false);

  const mode = mediaToPlay?.type || 'station';

  // @ts-ignore
  const [
    {data: dataZone}
  ]:
  [
    {data: Zone}
  ] =
    useQueries([
      {
        queryKey: ['zone', zoneSelected],
        queryFn: () => {
          if (zoneSelected) {
            return getZoneById(zoneSelected)
          }
        }
      }
    ]);

  const labels = useMemo(() => ({
    'schedule': t('Schedule.scheduleTitle'),
    'station': t('Settings.stationLabel'),
    'playlist': t('Settings.playlistLabel'),
  }), []);

  return (
    <Container>
      <ButtonMode
        onClick={() => setOpenModalMedia(true)}
      >
        <WrapperSelectInfo>
          <Text color={redBG} size='small'>{labels[mode]}</Text>
          {mediaToPlay && <SelectedMediaLabel media={mediaToPlay} />}
        </WrapperSelectInfo>
        <UnorderedListOutlined style={{ fontSize: '20px' }}  />
      </ButtonMode>
      <WrapperOptions>
        <WrapperIcon
          active={mode === 'schedule'}
          onClick={() => setOpenModalMediaSchedule(true)}
        >
          <Tooltip>{labels['schedule']}</Tooltip>
          <Icon
            active={mode === 'schedule'}
            src={scheduleIcon}
          />
        </WrapperIcon>
        <WrapperIcon
          active={mode === 'station'}
          onClick={() => setOpenModalMediaStation(true)}
        >
          <Tooltip>{labels['station']}</Tooltip>
          <Icon
            active={mode === 'station'}
            src={stationIcon}
          />
        </WrapperIcon>
        <WrapperIcon
          active={mode === 'playlist'}
          onClick={() => setOpenModalMediaPlaylist(true)}
          >
          <Tooltip>{labels['playlist']}</Tooltip>
          <Icon
            active={mode === 'playlist'}
            src={playlistIcon}
          />
        </WrapperIcon>
      </WrapperOptions>
      <Modal
        title=""
        visible={openModalMedia}
        width={800}
        onCancel={modalCancel}
        footer={[]}
        destroyOnClose={true}
      >
        <ModalMedias close={() => setOpenModalMedia(false)} />
      </Modal>
      <Modal
        title=""
        visible={openModalMediaSchedule}
        width={800}
        onCancel={() => setOpenModalMediaSchedule(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <ModalMediasButton close={() => setOpenModalMediaSchedule(false)} media='schedule' />
      </Modal>
      <Modal
        title=""
        visible={openModalMediaStation}
        width={800}
        onCancel={() => setOpenModalMediaStation(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <ModalMediasButton close={() => setOpenModalMediaStation(false)} media='station' />
      </Modal>
      <Modal
        title=""
        visible={openModalMediaPlaylist}
        width={800}
        onCancel={() => setOpenModalMediaPlaylist(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <ModalMediasButton close={() => setOpenModalMediaPlaylist(false)} media='playlist' />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const WrapperSelectInfo = styled.div`
  display: flex;
  flex-direction: column;

`;
const ButtonMode = styled.button`
  width: 100%;
  height: 50px;
  border: solid 1px ${lightgray};
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;
const WrapperOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
`;
const Tooltip = styled.div`
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  top: -28px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
`;
const WrapperIcon = styled.button<{ active: boolean }>`
  width: 40px;
  height: 40px;
  background-color: ${({ active }) => (active ? "#f0f0f0" : "white")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  position: relative;

  &:hover ${Tooltip} {
    opacity: 1;
  }
`;
const Icon = styled.img<{active: boolean}>`
  width: 20px;
  height: 20px;
  tint-color: ${({active}) => active ? black : grey};
`;

export default BarOptionsPlayback;
