import React from 'react';
import styled from 'styled-components';

import { black, lightgray, veryLightGrey} from '../../config/colors';
import { truncate } from '../../utils/functions';
// import components
import Text from '../atoms/Text';


interface Props {
  id: string;
  title: string;
  artist: string;
  image: string;
  type: 'ban' | 'like';
  valueOption: boolean;
  handleToggle: (id: string) => void;
  isLast?: boolean;
};

const TrackRow: React.FC<Props> = (props) => {

  function handlePress() {
    handleToggle(id);
  }

  const {
    id,
    title,
    artist,
    image,
    type,
    handleToggle,
    valueOption,
    isLast = false
  } = props;

  return (
    <Container last={isLast}>
      <Row>
        <Image src={image !== "" ? image : require('../../assets/images/coverart_generic.jpg')} />
        <Column>
          <Text color={black} size="normal" >{truncate(title, 27)}</Text>
          <Text color={lightgray} size="small">{truncate(artist, 27)}</Text>
        </Column>
      </Row>
      <Button onClick={handlePress} >
        {valueOption ? (
          <Icon src={type === 'ban'? require('../../assets/icons/banned.png') : require('../../assets/icons/liked.png')} />
        ):(
          <Icon src={type === 'ban'? require('../../assets/icons/ban.png') : require('../../assets/icons/like.png')} />
        )}
      </Button>
    </Container>
  );
}

const Container = styled.div<{last: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0.5px;
  border-top-color: ${veryLightGrey};
  border-bottom-color: ${veryLightGrey};
  border-bottom-width: ${({last}) => last ? '0.5px' : 0};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
`;
const Image = styled.img`
  width: 36px;
  height: 36px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  margin-right: 15px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50px;
`;
const Button = styled.div`
  width:30px;
  height:30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled.img`
  width: 26px;
  height: 24px;
`;

export default TrackRow;