import React, { useMemo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import {
  PlayCircleFilled,
  RightOutlined
} from '@ant-design/icons';
import { TailSpin } from 'react-loading-icons'

import {
  white,
  veryVeryLightGrey,
  veryLightLightGrey,
  redText,
  almostWhite,
  grey,
  purple
} from '../../config/colors';
import { MoodLabel, Genres, TargetGroupSchedule, StationOption, Styles } from '../../services/SoundSuitServiceTypes';
import { idToLabel, IdToLabel, moodSettings, listGenres} from '../../config/hardData';
import {
  setStation,
  updateStation,
  deleteStation,
  loadUserProfile,
} from '../../services/SoundSuitService';
import { usePlayer } from '../../store';
import { useUpdateTags } from '../../hooks';
// import components
import Text from '../atoms/Text';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';
import Tag from '../atoms/Tag';
import Header from '../molecules/Header';

export interface ParamsStation {
  id?: string
  name?: string;
  mood?: string;
  genre?: string;
  target?: string;
}

interface OptionsDetails {
  label: string;
  selection?:string[];
  onPressType?: 'link' | 'slot';
  onPressOption?: () => any;
  componentToDisplay?: React.ReactNode;
  placeholder?: string;
  type: 'mood' | 'genre' | 'target';
}

interface Props {};

const StationOptions:React.FC<Props> = props => {

  async function handleSelectionPlay() {
    try {
      await setMediaToPlay({id, type: 'station'});
      toggleRefreshTracks();
      navigate('/');
    } catch (error) {
      console.log("Error choosing media: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  }

  async function handleSave(): Promise<void> {
    function getMoodToSave(moods: MoodLabel[]): number[] {
      if (mood[0] && mood.length === 1) {
        return JSON.parse(moodSettings[moods[0]]);
      } else if (moods.length === 2 && moods[0] && moods[1]) {
        const tab1: number[] = JSON.parse(moodSettings[moods[0]]);
        const tab2: number[] = JSON.parse(moodSettings[moods[1]]);
        const total = tab1.concat(tab2);
        const min = Math.min(...total);
        const max = Math.max(...total);
        return [min, max];
      }
      return JSON.parse(moodSettings['smartmood']);
    }

    if (mood.length === 0 || !name || genre.length === 0) {
      return;
    }

    const arg: StationOption = {
      bpmRange: getMoodToSave(mood),
      genres: convertOldsToNews(genre),
      targetGroup: target as TargetGroupSchedule,
      name,
      addPermissions: true
    };
    // if id, means its an update
    if (id) {
      await updateItem({id, data: arg});
      await setMediaToPlay({id, type: 'station'});
      toggleRefreshTracks();
    } else {
      const res = await sendItem(arg);
      await setMediaToPlay({id, type: 'station'});
      toggleRefreshTracks();
      navigate('/library');
    }
  }

  async function handleDelete(): Promise<void> {
    await deleteItem(id as string);
  }

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { convertOldsToNews } = useUpdateTags();

  const [params, setSearchParams] = useSearchParams();
  const dataParams = Object.fromEntries([...params]);

  const {
    id,
    name: namePost,
    mood: moodPost,
    genre: genrePost,
    target: targetPost,
  } = dataParams as ParamsStation;

  const location = useLocation();
  const queryClient = useQueryClient();

  const {mutateAsync: sendItem, isLoading: isLoadingCreate} = useMutation(
    setStation,
    {
      onSuccess: () => {
        notification.open({
          message: '',
          description: t('Notifications.requestSuccess'),
        });
      },
      onError: error => {
        console.log('ERROR: ', error);
        notification.open({
          message: '',
          description: t('Notifications.requestFailed'),
        });
      },
    },
  );
  const {mutateAsync: updateItem, isLoading: isLoadingUpdate} = useMutation(
    updateStation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
        notification.open({
          message: '',
          description: t('Notifications.requestSuccess'),
        });
        navigate('/library');
      },
      onError: error => {
        console.log('ERROR: ', error);
        notification.open({
          message: '',
          description: t('Notifications.requestFailed'),
        });
      },
    },
  );
  const {mutateAsync: deleteItem, isLoading: isLoadingDelete} = useMutation(
    deleteStation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
        notification.open({
          message: '',
          description: t('Notifications.requestSuccess'),
        });
        navigate('/library');
      },
      onError: error => {
        console.log('ERROR: ', error);
        notification.open({
          message: '',
          description: t('Notifications.requestFailed'),
        });
      },
    },
  );

  const { data: dataUser } = useQuery('/userinfo', loadUserProfile);

  const targetGender = usePlayer.getState().state.settings.targetGender;
  const targetAge = usePlayer.getState().state.settings.targetAge;

  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;

  const defaultStyle = dataUser?.styles
    ? dataUser?.styles
    : (["alternative"] as unknown as Styles[]);

  const targetDefault: TargetGroupSchedule = {
    styles: defaultStyle,
    ageRange: {start: targetAge[0], end: targetAge[1]},
    gender: targetGender
  }

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const [isNew] = useState<boolean>(typeof id === 'undefined');
  const [name, setName] = useState<string>(namePost ? namePost : '');
  const [mood, ] = useState<MoodLabel[]>(moodPost ? JSON.parse(decodeURIComponent(moodPost)) : ['party']);
  const [genre, ] = useState<Genres[]>(genrePost ? JSON.parse(decodeURIComponent(genrePost)) : ['pop']);
  const [target, ] = useState<TargetGroupSchedule | undefined>(targetPost ? JSON.parse(decodeURIComponent(targetPost)) : targetDefault);
  const [images, setImages] = useState<string[]>([]);

  const dataOptions: OptionsDetails[] = useMemo(() => ([
    {
      label: t('Schedule.labelMood'),
      type: 'mood',
      selection: mood.reduce((acc, l) => {
        acc.push(currentIdToLabel[l].replace(/\(.*\)/, ''));
        return acc;
      }, []),
      onPressType: 'link',
      onPressOption: () => navigate(`mood${location.search}${location.search.includes('?') ? '&' : '?'}name=${encodeURIComponent(name)}`),
      placeholder: t('Settings.stationsPlaceholderMood'),
    },
    {
      label: t('Schedule.labelGenre'),
      type: 'genre',
      selection: genre.reduce((acc, l) => {
        acc.push(currentIdToLabel[l].replace(/\(.*\)/, ''));
        return acc;
      }, []),
      onPressType: 'link',
      onPressOption: () => navigate(`genre${location.search}${location.search.includes('?') ? '&' : '?'}name=${encodeURIComponent(name)}`),
      placeholder: t('Settings.stationsPlaceholderGenre'),
    },
    {
      label: t('Schedule.labelTarget'),
      type: 'target',
      selection: target?.styles.reduce((acc, l) => {
        acc.push(currentIdToLabel[l].replace(/\(.*\)/, ''));
        return acc;
      }, []),
      onPressType: 'link',
      onPressOption: () => navigate(`target${location.search}${location.search.includes('?') ? '&' : '?'}name=${encodeURIComponent(name)}`),
      placeholder: t('Settings.stationsPlaceholderTarget'),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [
      mood,
      genre,
      target,
      t,
      currentIdToLabel,
      navigate,
      location,
      name
    ]);

  useEffect(() => {
    const imgs = genre.map((genre: string) => listGenres.find(lg => lg.label === genre)?.img); 
    setImages(imgs);
  }, [genre]);

  return (
    <Container>
      <Header
        title={t('Settings.stationLabel')}
        handlePressSave={handleSave}
        inProgress={isLoadingCreate || isLoadingUpdate}
        customGoBack={() => navigate('/library')}
      />
      <Body contentContainerStyle={{alignItems: 'center', paddingBottom: 60, paddingTop: 20}}>
        <WrapperImage>
          <MultipleImagesThumbnail
            size='big'
            imgs={images}
          />
        </WrapperImage>
        {!isNew && (
          <WrapperSubHeader>
            <Text bold>{`By ${dataUser.email}`}</Text>
            <WrapperButtonPlay onClick={handleSelectionPlay}>
              <PlayCircleFilled style={{paddingLeft: 1, fontSize: 32}} />
            </WrapperButtonPlay>
          </WrapperSubHeader>
        )}
        <OptionRow>
          <LabelName>
            <Text color={purple} size='xsmall' >{t('Settings.stationNameLabel')}</Text>
          </LabelName>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('Settings.stationsPlaceholderName')}
          />
        </OptionRow>
        {dataOptions.map((d, i) => {
          const {
            onPressOption,
            label,
            selection,
            placeholder,
            type
          } = d;

          return (
            <OptionRow
              key={`${d.label}-i`}
              onClick={() => {
                if (onPressOption) {
                  onPressOption();
                }
              }}
            >
              <Text>{label}</Text>
              <RowCenter>
                {selection.length === 0 ? (
                  <RowCenter>
                    <Text size='xsmall' bold color={grey} >{`${placeholder}`}</Text>
                  </RowCenter>
                ):(
                  <RowCenter>
                    {selection.map((value, i) => {
                      if (i > 2) return null;
                      if (i === 2) {
                        return (
                          <Tag
                            key={`${value}-${i}`}
                            type={type}
                            size="xsmall"
                          >
                            ...
                          </Tag>
                        );
                      }
                      return (
                        <Tag
                          key={`${value}-${i}`}
                          type={type}
                          size="xsmall"
                          maxWidth={200}
                        >
                          {value}
                        </Tag>
                      );
                    })}
                  </RowCenter>
                )}
                <RightOutlined style={{ fontSize: 24 }} />
              </RowCenter>
            </OptionRow>
          );
        })}
        {!isNew && !isLoadingDelete && (
          <Button onClick={handleDelete}>
            <Text bold color={redText}>
              {t('Settings.stationDeleteLabel')}
            </Text>
          </Button>
        )}
        {!isNew && isLoadingDelete && (
          <WrapperLoading>
            <TailSpin stroke={redText} height="2em" />
          </WrapperLoading>
        )}
      </Body>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  padding: 0 15px 0 15px;
`;
const Button = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 40px;
  padding: 15px;
  cursor: pointer;
`;
const WrapperLoading = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 40px;
  padding: 15px;
`;
const OptionRow = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background-color: ${white};
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 5px 15px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LabelName = styled.div`
  position: absolute;
  top: -12px;
  left: 18px;
`;
const Input = styled.input`
  width: 100%;
  padding: 5px 0;
  border: none;
  &:focus {
    outline: none;
  }
`;
const WrapperImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;
const WrapperSubHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
`;
const WrapperButtonPlay = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default StationOptions;