import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../store';
import ScreenContainer from '../atoms/ScreenContainer';

const ImpersonateScreen: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const loginSuccess = useApp.getState().effects.loginSuccess;

  useEffect(() => {
    if (token) {
      try {
        const data = JSON.parse(token);
        loginSuccess(data, navigate);
      } catch (error) {
        console.error('Invalid impersonation token:', error);
        navigate('/welcome');
      }
    }
  }, [token, navigate, loginSuccess]);

  return (
    <ScreenContainer gradientBG>
      <div>Processing impersonation...</div>
    </ScreenContainer>
  );
};

export default ImpersonateScreen; 